import React from "react";
import { DiagonalCover } from "./DiagonalCover";
import { HeaderSize } from "./ParallaxHeader";
import { StraightCover } from "./StraightCover";

interface Props {
    size: HeaderSize,
    videoSrc: any,
    straightBottom?: boolean;
    className?: string,
}

export const VideoHeader: React.FC<Props> = ({ children, size, videoSrc, straightBottom = false, className = "" }) => {
    return (
        <header className={`video-header ${size}-height ${className}`}>
            <video controls={false} autoPlay={true} className="video" loop={true}>
                <source src={videoSrc} type="video/mp4" />
            </video>
            {straightBottom ?
                <StraightCover fill={"#ffffff"} />
                :
                <DiagonalCover fill={"#ffffff"} />
            }
            {children}
        </header>
    )
}