import React from "react";
import { EmployeeType } from "../../../content/pageData/CompanyPageData";
import Img from "gatsby-image";

interface Props {
    employee: EmployeeType,
}

export const Employee: React.FC<Props> = ({ employee }) => {
    return (
        <div className={`employee`}>
            <div className="image-container">
                <Img fluid={employee.picture} alt={employee.name} />
            </div>
            <h2>{employee.name}</h2>
            <h3>{employee.position}</h3>
        </div>
    )
}